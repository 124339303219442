<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->

        <h2 class="brand-text text-primary ml-1">
          Ongphra Wallpaper Creator
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Register V2" style="max-height: 80vh;" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1">
            สมัครสมาชิก 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            ร่วมเป็นส่วนหนึ่งกับเราได้แล้ววันนี้ !!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm" #default="{invalid}">
            <b-form class="auth-register-form mt-2" @submit.prevent="register">
              <!-- username -->
              <b-form-group label="ชื่อร้านค้า (แสดงบนแอป)" label-for="register-shop-name">
                <validation-provider #default="{ errors }" name="ชื่อร้านค้า" rules="required|min:6">
                  <b-form-input
                    v-model="form.shop_name"
                    name="register-shop-name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="OngphraStore"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group label="อีเมล" label-for="register-email">
                <validation-provider #default="{ errors }" name="อีเมล" vid="email" rules="required|email">
                  <b-form-input
                    id="register-email"
                    v-model="form.email"
                    name="register-email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="ongphra@gamil.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="register-password" label="รหัสผ่าน">
                <validation-provider #default="{ errors }" name="password" rules="required|min:8">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="register-password"
                      v-model="form.password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-password"
                      placeholder="••••••••"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group label-for="register-confirm-password" label="ยืนยันรหัสผ่าน">
                <validation-provider #default="{ errors }" name="confirm_password" rules="required|confirmed:password">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input
                      id="register-confirm-password"
                      v-model="form.confirm_password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="register-confirm-password"
                      placeholder="••••••••"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- first name -->
              <b-form-group label="ชื่อ" label-for="register-first-name">
                <validation-provider #default="{ errors }" name="ชื่อ" vid="firstname" rules="required|min:2">
                  <b-form-input
                    id="register-first-name"
                    v-model="form.first_name"
                    name="register-first-name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- last name -->
              <b-form-group label="นามสกุล" label-for="register-last-name">
                <validation-provider #default="{ errors }" name="นามสกุล" vid="lastname" rules="required|min:2">
                  <b-form-input
                    id="register-last-name"
                    v-model="form.last_name"
                    name="register-last-name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- phone number -->
              <b-form-group label="เบอร์โทรศัพท์" label-for="register-phone-number">
                <validation-provider
                  #default="{ errors }"
                  name="เบอร์โทรศัพท์"
                  vid="phoneNumber"
                  rules="required|numeric|min:9|max:10"
                >
                  <b-form-input
                    id="register-phone-number"
                    v-model="form.phone_number"
                    name="register-phone-number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="xxx-xxx-xxxx"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- <b-form-group>
                <validation-provider #default="{ errors }" name="privacy" rules="required">
                  <b-form-checkbox id="register-privacy-policy" v-model="status" name="privacy">
                    ฉันยอมรับ
                    <b-link>privacy policy & terms</b-link>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group> -->

              <button-widget
                type="submit"
                text="สมัครสมาชิก"
                block
                variant="primary"
                :loading="formLoading"
                :disabled="false && invalid"
              />
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>มีบัญชีแล้วใช่ไหม?</span>
            <b-link :to="{ name: 'auth-login' }">
              <span>&nbsp;เข้าสู่ระบบ</span>
            </b-link>
          </p>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
    <modal-otp-widget
      ref="refModalOtp"
      :phone-number="form.phone_number"
      :ref-code="otpRef"
      :loading="otpLoading"
      @confirm="sandOtpVerify"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    // VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/ongphra/register.svg'),
      // validation
      required,
      email,
      form: {
        email: '',
        shop_name: '',
        password: '',
        confirm_password: '',
        first_name: '',
        last_name: '',
        phone_number: '',
      },
      formLoading: false,
      otpLoading: false,
      otpRef: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
      //   return this.sideImg
      // }
      return this.sideImg
    },
  },
  methods: {
    async register() {
      const validate = await this.$refs.registerForm.validate()
      if (!validate) return
      this.formLoading = true
      const resp = await this.api.postV2('api/partners/register', this.form, this)
      // console.log('register', resp)
      this.formLoading = false

      if (!resp) return

      if (resp.code === 200) {
        this.otpRef = resp.data.ref
        this.$refs.refModalOtp.show()
        return
      }
      if (resp.code === 405 || resp.code === 406) {
        this.gDisplayToast('สมัครสมาชิกไม่สำเร็จ', resp.data.message, 'danger')
      }
    },
    async sandOtpVerify(otp) {
      const requestData = {
        ...this.form,
        OTP_TOKEN: Number(otp),
        OTP_REF: this.otpRef,
      }

      this.otpLoading = true

      const resp = await this.api.postV2('api/partners/register', requestData, this)
      // console.log('sandOtpVerify', resp)
      this.otpLoading = false

      if (!resp) return
      if (resp.code === 200) {
        this.$refs.refModalOtp.hide()
        this.gDisplayToast('สมัครสมาชิกสำเร็จ', 'สามารถเข้าสู่ระบบเพื่อใช้งานได้แล้ว')
        this.$router.push({ name: 'auth-login', replace: true })
        return
      }

      if (resp.code === 406) {
        this.gDisplayToast('โปรดสมัครใหม่อีกครั้ง', resp.data.message, 'danger')
        this.$refs.refModalOtp.hide()
        return
      }

      if (resp.code === 405) {
        this.gDisplayToast('สมัครสมาชิกไม่สำเร็จ', resp.data.message, 'danger')
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
