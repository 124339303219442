var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" Ongphra Wallpaper Creator ")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{staticStyle:{"max-height":"80vh"},attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" สมัครสมาชิก 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" ร่วมเป็นส่วนหนึ่งกับเราได้แล้ววันนี้ !! ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"ชื่อร้านค้า (แสดงบนแอป)","label-for":"register-shop-name"}},[_c('validation-provider',{attrs:{"name":"ชื่อร้านค้า","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"register-shop-name","state":errors.length > 0 ? false : null,"placeholder":"OngphraStore"},model:{value:(_vm.form.shop_name),callback:function ($$v) {_vm.$set(_vm.form, "shop_name", $$v)},expression:"form.shop_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"อีเมล","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"อีเมล","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false : null,"placeholder":"ongphra@gamil.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"รหัสผ่าน"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"••••••••"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-confirm-password","label":"ยืนยันรหัสผ่าน"}},[_c('validation-provider',{attrs:{"name":"confirm_password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-confirm-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-confirm-password","placeholder":"••••••••"},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"ชื่อ","label-for":"register-first-name"}},[_c('validation-provider',{attrs:{"name":"ชื่อ","vid":"firstname","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-first-name","name":"register-first-name","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"นามสกุล","label-for":"register-last-name"}},[_c('validation-provider',{attrs:{"name":"นามสกุล","vid":"lastname","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-last-name","name":"register-last-name","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"เบอร์โทรศัพท์","label-for":"register-phone-number"}},[_c('validation-provider',{attrs:{"name":"เบอร์โทรศัพท์","vid":"phoneNumber","rules":"required|numeric|min:9|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-phone-number","name":"register-phone-number","state":errors.length > 0 ? false : null,"placeholder":"xxx-xxx-xxxx"},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button-widget',{attrs:{"type":"submit","text":"สมัครสมาชิก","block":"","variant":"primary","loading":_vm.formLoading,"disabled":false && invalid}})],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("มีบัญชีแล้วใช่ไหม?")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',[_vm._v(" เข้าสู่ระบบ")])])],1)],1)],1)],1),_c('modal-otp-widget',{ref:"refModalOtp",attrs:{"phone-number":_vm.form.phone_number,"ref-code":_vm.otpRef,"loading":_vm.otpLoading},on:{"confirm":_vm.sandOtpVerify}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }